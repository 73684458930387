/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
// import browser from '@utils/browser/browser';
import env from '@lib/envs/env';
import {
  setOpenInstallAppBannerDismissed,
  isOpenInstallAppBannerDismissed
} from '@lib/cookie/openInstallAppBannerDismissed';
import ButtonPrimary from '@components/shared/buttons/ButtonPrimary/ButtonPrimary';
import RatingFill from '@components/shared/RatingFill/RatingFill';
import classNames from 'classnames';
import { useTheme } from '@components/theme';

import browser from '@utils/browser/browser';
import { formatNumberToShortString } from '@lib/number';
import getiOSAppInfo, { IAppInfo } from '@lib/iOSappInfo';
import styles from './MobileAppConversionBanner.module.scss';

const { OPEN_INSTALL_IOS_APP_DEEP_LINK } = env;

interface IMobileAppConversionBannerProps {
  iosDeeplinkPath: string | undefined;
  unbounceBannerHeight?: number;
}

/**
 * This banner opens at the top of iPhone devices where implemented.
 * iosDeeplinkPath prop drives where the link navigates the user inside the app.
 * Branch determines if app is installed, if not navigates user device to app store page.
 * User dismissal sets a browser cookie expiring 1 week. Banner will not open if cookie is set.
 */
export default function MobileAppConversionBanner({
  iosDeeplinkPath = '',
  unbounceBannerHeight = 0,
}: IMobileAppConversionBannerProps) {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const [iOSAppInfo, setiOSAppInfo] = useState<IAppInfo>({
    averageUserRatingForCurrentVersion: 4.5,
    userRatingCount: 4900
  });

  useEffect(() => {
    if (isOpen) {
      // @ts-ignore
      document.body.style['margin-top'] = '76px';
      document.body.classList.add('branch-banner-is-active');
    } else {
      // @ts-ignore
      document.body.style['margin-top'] = '0';
      document.body.classList.remove('branch-banner-is-active');
    }

    return () => {
      // @ts-ignore
      document.body.style['margin-top'] = '0';
      document.body.classList.remove('branch-banner-is-active');
    };
  }, [isOpen]);

  useEffect(() => {
    if (browser.isIOS() && !isOpenInstallAppBannerDismissed()) {
      getiOSAppInfo().then((appInfo) => {
        setiOSAppInfo(appInfo);
      }).finally(() => {
        setIsOpen(true);
      });
    }
  }, []);

  const close = () => {
    setOpenInstallAppBannerDismissed(); // sets a cookie to prevent opening for 7 days
    setIsOpen(false);
  };

  const deepLink = buildDeepLink(iosDeeplinkPath);

  return (
    <>
      {isOpen ? (
        <div
          className={styles.MobileAppConversionBanner}
          style={{ marginTop: unbounceBannerHeight }}
        >
          <button className={styles.DismissButton} onClick={close} type="button">
            <span className="havenly-icon close" />
          </button>
          <img
            alt="havenly-ios-icon"
            className={styles.IosAppIcon}
            src="/static/havenly-ios-icon-320.png"
          />
          <div className={styles.DetailsContainer}>
            <p className={styles.Text}>Designing your home is even easier on our iOS App</p>
            <div className={styles.Reviews}>
              <RatingFill
                compact
                fillColor="gold"
                strokeColor="transparent"
                rating={iOSAppInfo.averageUserRatingForCurrentVersion || 4.5}
                className={styles.Ratings}
                id={`app-rating`}
              />
              <p className={styles.ReviewCount}>
                ({formatNumberToShortString(iOSAppInfo.userRatingCount) || '4.8k'})
              </p>
            </div>
          </div>
          <div>
            <ButtonPrimary
              className={classNames(styles.OpenButton, theme.styles.Primary)}
              href={deepLink}
            >
              Open
            </ButtonPrimary>
          </div>
        </div>
      ) : null}
    </>
  );
}

function buildDeepLink(iosDeeplinkPath: string | undefined) {
  let link = OPEN_INSTALL_IOS_APP_DEEP_LINK;

  if (iosDeeplinkPath) {
    link += `?$ios_deeplink_path=${encodeURIComponent(iosDeeplinkPath)}`;
  }

  return link;
}
