// eslint-disable-next-line import/prefer-default-export
export const ordinalSuffix = (num: number) => {
  if (num === 11 || num === 12 || num === 13) return 'th';

  const lastDigit = num.toString().slice(-1);

  switch (lastDigit) {
    case '1': return 'st';
    case '2': return 'nd';
    case '3': return 'rd';
    default: return 'th';
  }
};

/**
 * Formats a number into a shortened string representation (e.g. 4800 -> '4.8k')
 * @param num The number to format
 * @returns Formatted string representation of the number
 */
export function formatNumberToShortString(num: number): string {
  if (num < 1000) {
    return num.toString();
  }

  const thousands = num / 1000;
  const formatted = Math.round(thousands * 10) / 10;

  return `${formatted.toString().replace(/\.0$/, '')}k`;
}
