import logger, { Logger } from '@lib/logger';
import axios from 'axios';

const log: Logger = logger('iOSAppInfoClient');

const APP_ID = '1149153371';
const APP_STORE_URL = `https://itunes.apple.com/lookup`;

export interface IAppInfo {
  averageUserRatingForCurrentVersion: number;
  userRatingCount: number;
}

interface Response {
  results: Array<IAppInfo>;
}

export default async function getiOSAppInfo(): Promise<IAppInfo> {
  try {
    const { data } = await axios.get<Response>(APP_STORE_URL, {
      params: { id: APP_ID }
    });

    if (!data.results) {
      throw new Error('No results found');
    }

    return {
      averageUserRatingForCurrentVersion: data.results[0].averageUserRatingForCurrentVersion,
      userRatingCount: data.results[0].userRatingCount
    };
  } catch (error: any) {
    log.error(error?.response?.data, 'Error fetching iOS app info from iTunes');
    throw error;
  }
}
